'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.devices.factory:DeviceManager

 # @description

###
angular
  .module 'mundoAdmin.devices'
  .factory 'DeviceManager', [
    'MundoDefaultManager'
    'TenantManager'
    'DeviceTypeManager'
    'DeviceStatusManager'
    'DeviceInterfaceTypeManager'
    'DeviceInterfacesManager'
    '$mdDialog'
    '$state'
    '$timeout'
    '$rootScope'
    (
      MundoDefaultManager
      TenantManager
      DeviceTypeManager
      DeviceStatusManager
      DeviceInterfaceTypeManager
      DeviceInterfacesManager
      $mdDialog
      $state
      $timeout
      $rootScope
    )->
      DeviceManagerBase = new MundoDefaultManager()
  #   base
      DeviceManagerBase.setUrl('devices')
      DeviceManagerBase.setAutoTenant()
      DeviceManagerBase.setNewObject(['label', 'serialNumber', 'deviceType', 'deviceStatus'])
      DeviceManagerBase.setUpdateObject(['label', 'serialNumber', 'deviceStatus'])
      DeviceManagerBase.setSearchFields(['label', 'serialNumber', 'deviceType', 'deviceStatus.label', 'tenant.label'])


      DeviceManagerBase.getNewForm = ->
        [
          key: 'label'
          name: 'label'
          type: 'input'
          templateOptions:
            label: 'label'
            placeholder: 'label'
            required: true
        ,
          key: 'serialNumber'
          name: 'serialNumber'
          type: 'input'
          templateOptions:
            label: 'Serial Number'
            placeholder: '123123123'
            required: true
        ,
          key: 'deviceType'
          name: 'deviceType'
          type: 'select'
          templateOptions:
            label: 'Device Type'
            placeholder: 'Device Type'
            required: true
            labelProp: "label"
            valueProp: "code"
            options: DeviceTypeManager.getListCustom()
        ,
          key: 'deviceStatus'
          name: 'deviceStatus'
          type: 'select'
          templateOptions:
            label: 'Device Status'
            placeholder: 'Device Status'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: DeviceStatusManager.getFullList({'sort' : 'code,ASC'}).$object
        ]

      DeviceManagerBase.getEditForm = (data)->
        [
          key: 'label'
          name: 'label'
          type: 'input'
          defaultValue: data.label
          templateOptions:
            label: 'label'
            placeholder: 'label'
            required: true
        ,
          key: 'serialNumber'
          name: 'serialNumber'
          type: 'input'
          defaultValue: data.serialNumber
          templateOptions:
            label: 'Serial Number'
            placeholder: '123123123'
            required: true
        ,
        #   key: 'deviceType'
        #   name: 'deviceType'
        #   type: 'select'
        #   defaultValue: data.deviceType
        #   templateOptions:
        #     label: 'Device Type'
        #     placeholder: 'Device Type'
        #     required: true
        #     labelProp: "label"
        #     valueProp: "code"
        #     options: DeviceTypeManager.getListCustom()
        # ,
          key: 'deviceStatus'
          name: 'deviceStatus'
          type: 'select'
          defaultValue: data.deviceStatus.id
          templateOptions:
            label: 'Device Status'
            placeholder: 'Device Status'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: DeviceStatusManager.getFullList({'sort' : 'code,ASC'}).$object
        ]

      DeviceManagerBase.getListSchema = ->
        [
          key: 'label'
          title: 'Label'
          sort: 'label'
        ,
          key: 'serialNumber'
          title: 'Serial Number'
          sort: 'serialNumber'
        ,
          key: 'deviceType'
          title: 'Device Type'
          sort: 'deviceType'
        ,
          key: 'deviceStatus.label'
          title: 'Device Status'
          sort: 'deviceStatus.label'
        # ,
        #   key: 'tenant.label'
        #   title: 'datatable.label.tenant'
        #   sort: 'tenant.label'
        ,
          key: 'createdAt'
          title: 'datatable.label.created.at'
          sort: 'createdAt'
          type: 'dateTime'
          hideInList: true
        ]

      DeviceManagerBase.getDetailTemplateUrl = ->
        'mundo-admin/devices/views/devices-detail.tpl.html'

      DeviceManagerBase.getDetailModalTemplateUrl = ->
        'mundo-admin/devices/views/devices-detail-modal.tpl.html'

  #   interfaces
      DeviceManagerBase.addInterface = (deviceId, data) ->
        DeviceInterfaceTypeManager.new(deviceId, data)

      DeviceManagerBase.getInterfaceTypeForm = ->
        DeviceInterfaceTypeManager.getInterfaceTypeForm()

      DeviceManagerBase.loadFieldsByType = (type) ->
        DeviceInterfaceTypeManager.getFormByType(type)

      DeviceManagerBase.removeInterface = (deviceId, interfaceId) ->
        DeviceInterfaceTypeManager.removeInterface(deviceId, interfaceId)

      Interfaces =
        [
          icon: 'settings_cell'
          text: 'datatable.tooltips.addDeviceInterfaces'
          action: (id) ->
            $mdDialog.show
              templateUrl: 'mundo-admin/devices/views/device-interfaces.modal.tpl.html'
              controller: 'DeviceInterfacesCtrl'
              controllerAs: 'ctrl'
              skipHide: true
              locals:
                id: id
        ]

      DeviceManagerBase.addExtraAction(Interfaces)

      DeviceManagerBase.addDetailEditAction('editDeviceInterface',
        [
          title: 'Edit Device interface'
          entityManager: DeviceInterfacesManager
        ]
      )

      DeviceManagerBase.editPermission = 'manage all MundoMosaDeviceBundle:Device entities'

      DeviceManagerBase.getOne = DeviceManagerBase.one

      DeviceManagerBase.one = (id) ->
        DeviceManagerBase.getOne(id)
          .then((result) ->
            result.units = []
            #get the unit(s) that use this device
            UnitsManager = new MundoDefaultManager()
            UnitsManager.setUrl('units')
            UnitsManager.getList(undefined, {
              'filter[0]': 'unitDevices.device.id,' + id
              'filter[1]': 'unitDevices.deletedAt,NULL'
              'filter[3]': 'unitDevices.endDate,GTE ' + moment.utc().format()
            })
              .then((data) ->

                for unit in data
                  result.units.push unit
              )

            return result
          )

      DeviceManagerBase
  ]
